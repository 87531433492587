<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-toolbar card>
      <v-layout row>
        <v-flex
          v-if="value === 'annual'"
          xs3
        >
          <v-select
            v-model="year"
            :items="years"
            prepend-inner-icon="event"
          />
        </v-flex>
        <v-flex
          v-else-if="value === 'monthly'"
          xs3
        >
          <v-menu
            v-model="menu"
            lazy
            close-on-content-click
            transition="scale-transition"
            full-width
            min-width="290px"
          >
            <v-text-field
              slot="activator"
              v-model="monthOf"
              prepend-inner-icon="event"
              readonly
            />
            <v-date-picker
              v-model="monthOf"
              :max="maxDate"
              :allowed-dates="allowedMonths"
              type="month"
              year-icon="date_range"
            />
          </v-menu>
        </v-flex>
      </v-layout>
    </v-toolbar>
    <v-card
      v-if="kpi2022 && value === 'monthly'"
      color="black"
    >
      <v-card-text
        style="height: 10px;
        position: relative"
      >
        <v-btn
          absolute
          dark
          fab
          top
          right
          :ripple="false"
          large
          :color="kpiAchievement ? `black` : `white`"
        >
          <v-avatar
            size="50px"
            :color="kpiAchievement ? kpiColor : `white`"
          >
            <span
              v-if="(typeof kpiAchievement === 'number' && kpiAchievement > 0) || kpiAchievement !== '0'"
              class="white--text subheader"
              v-html="'KPI<br> 0' + kpiAchievement"
            />
            <span
              v-else
              class="black--text subheader"
              v-html="'KPI<br>VOID'"
            />
          </v-avatar>
        </v-btn>
      </v-card-text>
    </v-card>
    <v-alert
      :value="alert"
      type="error"
      transition="scale-transition"
    >
      {{ alertMessage }}
    </v-alert>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      :loading="loading"
      :expand="expand"
      item-key="date"
      class="elevation-1"
      disable-initial-sort
      hide-actions
    >
      <template #items="props">
        <tr
          :active="props.selected"
          @click="toggleSelection(props)"
        >
          <td
            v-if="value === 'annual'"
            class="text-xs-left"
          >
            {{ props.item.date }}
          </td>
          <td
            v-else
            class="text-xs-center"
          >
            {{ props.item.date }}
          </td>
          <td class="text-xs-right">
            {{ props.item.reload }}
          </td>
          <template v-if="kpi2022 && value === 'monthly'">
            <td
              v-if="kpiAchievement === '1' || kpiAchievement === '1A' || kpiAchievement === '1B' || kpiAchievement === '1C'"
              class="text-xs-right info--text"
            >
              {{ props.item.kpi01 }}
            </td>
            <td
              v-else
              class="text-xs-right"
            >
              {{ props.item.kpi01 }}
            </td>
            <td
              v-if="kpiAchievement === '2'"
              class="text-xs-right info--text"
            >
              {{ props.item.kpi02 }}
            </td>
            <td
              v-else
              class="text-xs-right"
            >
              {{ props.item.kpi02 }}
            </td>
            <td
              v-if="kpiAchievement === '3'"
              class="text-xs-right info--text"
            >
              {{ props.item.kpi03 }}
            </td>
            <td
              v-else
              class="text-xs-right"
            >
              {{ props.item.kpi03 }}
            </td>
          </template>
          <template v-else>
            <td class="text-xs-right">
              {{ props.item.incentive }}
            </td>
          </template>
        </tr>
      </template>
      <template #expand="props">
        <v-layout column>
          <v-flex xs10>
            <v-card-text color="darken-5">
              <v-data-table
                :headers="detailHeaders"
                :items="props.item.item"
                item-key="tier"
                class="elevation-1 px-3"
                disable-initial-sort
                hide-actions
              >
                <template #items="thisProps">
                  <tr>
                    <td class="text-xs-center info--text">
                      {{ thisProps.item.tier }}
                    </td>
                    <td class="text-xs-right info--text">
                      {{ thisProps.item.reload }}
                    </td>
                    <td class="text-xs-right info--text">
                      {{ thisProps.item.incentive }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-flex>
        </v-layout>
      </template>
      <template #footer>
        <tr>
          <td class="text-xs-right">
            Total:
          </td>
          <td class="text-xs-right info">
            RM {{ totalReload }}
          </td>
          <template v-if="kpi2022 && value === 'monthly'">
            <td class="text-xs-right info">
              RM {{ totalKpi01 }}
            </td>
            <td class="text-xs-right info">
              RM {{ totalKpi02 }}
            </td>
            <td class="text-xs-right info">
              RM {{ totalKpi03 }}
            </td>
          </template>
          <template v-else>
            <td
              class="text-xs-right info"
            >
              RM {{ totalIncentive }}
            </td>
          </template>
        </tr>
      </template>
    </v-data-table>
  </panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'
import security from '@/security'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      alert: false,
      alertMessage: null,
      allowedMonths: function (month) {
        const ym = month.split('-')
        const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
        const fromDate = DateTime.local(2020, 4, 1).setZone('Asia/Kuala_Lumpur')
        const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

        return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
      },
      breadcrumbs: [
        {
          text: 'Incentives', disabled: false, to: '/incentives',
        },
        {
          text: 'RI', disabled: false, to: '/royalty_incentives',
        },
        {
          text: this.title, disabled: true,
        },
      ],
      expand: false,
      items: [],
      kpiAchievement: 0,
      loading: false,
      maxDate: '',
      menu: false,
      monthOf: null,
      selected: [],
      totalIncentive: 0.00,
      totalKpi01: 0.00,
      totalKpi02: 0.00,
      totalKpi03: 0.00,
      totalReload: 0.00,
      year: null,
      years: [],
    }
  },
  computed: {
    detailHeaders: function () {
      const headers = [
        {
          text: 'Tier',
          align: 'center',
          sortable: false,
          value: 'tier',
        },
        {
          text: 'Reload (RM)',
          align: 'right',
          sortable: false,
          value: 'reload',
        },
        {
          text: 'Royalty Incentive (RM)',
          align: 'right',
          sortable: false,
          value: 'incentive',
        },
      ]

      return headers
    },
    kpiColor: function () {
      if (typeof this.kpiAchievement === 'number') {
        switch (this.kpiAchievement * 1) {
          case 3: return '#800000'
          case 2: return 'orange darken-3'
          case 1: return 'grey'
          default: return 'white'
        }
      } else {
        if (this.kpiAchievement === '1A' || this.kpiAchievement === '1') {
          return 'grey'
        } else if (this.kpiAchievement === '1B') {
          return 'grey darken-2'
        } else if (this.kpiAchievement === '1C') {
          return 'grey darken-3'
        } else if (this.kpiAchievement === '2') {
          return 'orange darken-3'
        } else if (this.kpiAchievement === '3') {
          return '#800000'
        } else {
          return 'white'
        }
      }
    },
    kpi2022: function () {
      if (!this.monthOf) return false

      const ym = this.monthOf.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
      const fromDate = DateTime.local(2022, 3, 1).setZone('Asia/Kuala_Lumpur')
      const toDate = DateTime.local(2024, 3, 31).setZone('Asia/Kuala_Lumpur')
      // const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
      const applicableDealer = security.me.dealerRank() === 'Dealer' || security.me.dealerRank() === 'Sub Dealer'

      return Interval.fromDateTimes(fromDate, toDate).contains(whatMonth) && applicableDealer
    },
    kpiSept2022: function () {
      if (!this.monthOf) return false

      const ym = this.monthOf.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
      const fromDate = DateTime.local(2022, 9, 1).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
      const applicableDealer = security.me.dealerRank() === 'Dealer' || security.me.dealerRank() === 'Sub Dealer'

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth) && applicableDealer
    },
    headers: function () {
      const header = [
        {
          text: this.value === 'annual' ? 'Month' : 'Day',
          align: this.value === 'annual' ? 'left' : 'center',
          sortable: false,
          value: 'date',
        },
        {
          text: 'Reload (RM)',
          align: 'right',
          sortable: false,
          value: 'reload',
        },
      ]
      if (this.kpi2022 && this.value === 'monthly') {
        header.push(
          {
            text: this.kpiSept2022 ? (this.kpiAchievement === '1C' ? 'KPI 01C' : (this.kpiAchievement === '1B' ? 'KPI 01B' : 'KPI 01A')) : 'KPI 01',
            align: 'right',
            sortable: false,
            value: 'kpi01',
          },
          {
            text: 'KPI 02',
            align: 'right',
            sortable: false,
            value: 'kpi02',
          },
          {
            text: 'KPI 03',
            align: 'right',
            sortable: false,
            value: 'kpi03',
          },
        )
      } else {
        header.push({
          text: 'Royalty Incentive (RM)',
          align: 'right',
          sortable: false,
          value: 'incentive',
        })
      }

      return header
    },
  },
  watch: {
    monthOf: function (val) {
      if (val && this.value === 'monthly') {
        this.getRoyaltyIncentive(val)
      }
    },
    year: function (val) {
      if (val && this.value === 'annual') {
        this.getRoyaltyIncentive(val)
      }
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    const maxYear = now.toFormat('yyyy')
    for (let year = maxYear; year >= 2020; year--) {
      this.years.push(year)
    }
    this.year = maxYear
    this.maxDate = this.monthOf = now.toFormat('yyyy-LL')
  },
  methods: {
    getRoyaltyIncentive: function (time) {
      const param = { time }
      this.loading = true
      this.$rest.get('getRoyaltyIncentive.php', createGetParams(param))
        .then(function (response) {
          this.items = response.data.item
          this.totalReload = response.data.totalReload
          this.totalIncentive = response.data.totalIncentive
          this.totalKpi01 = response.data.totalKpi01
          this.totalKpi02 = response.data.totalKpi02
          this.totalKpi03 = response.data.totalKpi03
          this.kpiAchievement = response.data.kpiAchievement
          this.loading = false
        }.bind(this)).catch(error => {
          let message = error.message
          if (error.response) {
            message += ': ' + error.response.data
          }
          this.alertMessage = message
          this.alert = true
          this.loading = false
        })
    },
    toggleSelection: function (item) {
      item.selected = !item.selected
      item.expanded = !item.expanded
      if (this.selected.length > 1) this.selected.shift()
    },
  },
}
</script>
